.daterangepickerteal {
  .mat-calendar-body-selected {
    background: $nexus-green;
  }
  .mat-calendar-body-in-range {
    &:before {
      background: $nexus-green;
    }
    .mat-calendar-body-cell-content.mat-focus-indicator {
      color: #fff;
    }
  }
  .mat-calendar-body-preview-start {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
  }
  .mat-calendar-body-preview-end {
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }
  .mat-calendar-body-preview-start,
  .mat-calendar-body-in-preview,
  .mat-calendar-body-preview-end {
    background: $nexus-green;
    .mat-calendar-body-cell-content {
      color: #fff;
    }
    .mat-calendar-body-cell-preview {
      border: 0;
    }
  }
  .mat-calendar-body-preview-start:hover,
  td.mat-calendar-body-cell.ng-star-inserted.mat-calendar-body-in-preview:hover,
  .mat-calendar-body-preview-end:hover {
    .mat-calendar-body-cell-content.mat-focus-indicator {
      background-color: transparent;
    }
  }
}
