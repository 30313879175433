@function text-contrast($input-color) {
  // Return white or black as a contrasting text colour to the supplied ($n) colour
  $this-calculated-color-brightness: round((red($input-color) * 299)+ (green($input-color) * 587)+ (blue($input-color) * 114)/1000);
  $this-calculated-light-color: round((red(#ffffff) * 299)+ (green(#ffffff) * 587)+ (blue(#ffffff) * 114)/1000);
  @if abs($this-calculated-color-brightness) < ($this-calculated-light-color/2) {
    @return white;
  }
  @return black;
}

$nexus-green: #40a59f;
$grey: #f2f3f5;
$light-grey: #f8fafc;
$dark-grey: #ccc;
$nexus-dark-blue: #3f4a58;
$cyan: #26a69a !default;
$main: $cyan;
$red: #db0000;
$dark-teal: #009999;
$darker-grey: #475569;

$nexus-yellow: #edb13d;
$nexus-red: #fb6169;
$nexus-grey: #f3f5f9;
$nexus-blue: #7592cc;
$nexus-orange: #fbb261;
$nexus-pink: #fb95f8;

$nexus-appointment-not-started: #cbd5e1;
$nexus-appointment-on-route: #fef3c7;
$nexus-appointment-on-site: #fcd34d;
$nexus-appointment-started: #f59e0b;
$nexus-appointment-completed: #10b981; //done
$nexus-appointment-paused: #7676a8;
$nexus-appointment-aborted: #dc2626; //done
$nexus-appointment-booked: #999595; //done
$nexus-appointment-scheduled: #cbd5e1;

$nexus-appointment-not-startedv2: #f1f5f9;
$nexus-appointment-on-routev2: #fef3c7;
$nexus-appointment-on-sitev2: #fcd34d;
$nexus-appointment-startedv2: #f59e0b;
$nexus-appointment-completedv2: #10b981;
$nexus-appointment-abortedv2: #dc2626;
$nexus-appointment-pausedv2: #7e7ea2;
// $nexus-appointment-bookedv2: #999595;
$nexus-appointment-bookedv2: #cbd5e1;
$nexus-appointment-scheduledv2: #4a4a87;

$nexus-appointment-not-started-textv2: #0f172a;
$nexus-appointment-on-route-textv2: #0f172a;
$nexus-appointment-on-site-textv2: #0f172a;
$nexus-appointment-paused-textv2: #fff;
$nexus-appointment-started-textv2: #fff;
$nexus-appointment-completed-textv2: #fff;
$nexus-appointment-aborted-textv2: #fff;
$nexus-appointment-booked-textv2: #0f172a;
$nexus-appointment-scheduled-textv2: #0f172a;

$nexus-appointment-not-started-text: $nexus-appointment-not-started-textv2;
$nexus-appointment-on-route-text: $nexus-appointment-on-route-textv2;
$nexus-appointment-on-site-text: $nexus-appointment-on-site-textv2;
$nexus-appointment-started-text: $nexus-appointment-started-textv2;
$nexus-appointment-completed-text: $nexus-appointment-completed-textv2;
$nexus-appointment-paused-text: $nexus-appointment-paused-textv2;
$nexus-appointment-aborted-text: $nexus-appointment-aborted-textv2;
$nexus-appointment-booked-text: $nexus-appointment-booked-textv2;
$nexus-appointment-scheduled-text: $nexus-appointment-scheduled-textv2;

$beboc-primary: #3ba5a0;
