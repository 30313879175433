@import './colors';
@import './customscss/daterangepickerteal';
@import './customscss/ball-triangle-path';
@import 'viewerjs/dist/viewer.css';

body {
  --mdc-typography-body1-font-size: 13px;
  --mdc-dialog-supporting-text-size: 13px;
}
.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-size: 13px;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
}
::-webkit-scrollbar {
  width: 0.7em;
}
::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  outline: 0px solid slategrey;
  border-radius: 5px;
}
body .cdk-drag-preview {
  box-shadow: none;
  background: transparent;
}
.mat-dialog-container {
  overflow: inherit !important;
}
html .link:focus {
  outline: 0;
}
input {
  border-color: rgb(169, 169, 169);
}
.lato {
  font-family: 'Lato', sans-serif;
}
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -webkit-appearance: none;
  border-radius: 6px;
  border: solid 1px #3ba5a0;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-color: white;
  color: #000;
}
textarea {
  border: solid 1px #3ba5a0;
}
.word-break {
  word-break: break-word;
}
.w-300 {
  width: 300px;
}
.hoverdim {
  transition: opacity 0.15s ease-in;
  &:hover {
    opacity: 0.5;
    transition: opacity 0.15s ease-in;
  }
}
.select-with-search {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -webkit-appearance: none;
  border-radius: 6px;
  border: solid 1px #3ba5a0;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-color: white;
  color: #000;
}
select {
  padding-right: 20px !important;
}

.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.check-hand {
  cursor: grab;
}

.svg {
  filter: invert(1);
}

.f9 {
  font-size: 8px;
}

/* Widths */
@media screen and (min-width: 30em) {
  .w-15-ns {
    width: 15% !important;
  }

  .w-85-ns {
    width: 85% !important;
  }
}

.w-15 {
  width: 15% !important;
}

.w-55 {
  width: 55% !important;
}

.w-95 {
  width: 95% !important;
}

.w-85 {
  width: 85% !important;
}

.w-025 {
  width: 2.5% !important;
}

.w-125 {
  width: 12.5% !important;
}

.left-menu-widths {
  min-width: 210px;
  width: 210px;
  max-width: 210px;
}

.bg-nexus-blue {
  background: #3f4a59;
}

.bg-orange {
  background: $nexus-orange;
}

.bg-pink {
  background: $nexus-pink;
}

.bg-light-blue {
  background: #bbdefb;
}

.bg-grey {
  background: #3f4a5914;
}

.nexus-green {
  color: #45a59f;
}
.neutralgray800 {
  color: #1e293b;
}
.neutralgray700 {
  color: #334155;
}
.neutralgray600 {
  color: #475569;
}
.neutralgray400 {
  color: #94a3b8;
}
.neutralgray50 {
  color: #f8fafc;
}
.borderneuturalgray30005 {
  border: 0.5px solid #cbd5e1;
}
.mat-form-field-wrapper {
  padding: 0;
}

.nexus-red {
  color: $nexus-red;
}

.nexus-dark-blue {
  color: $nexus-dark-blue;
}

.h-40 {
  height: 40px;
}

.h-80 {
  height: 80px;
}

/* Custom animations */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  //font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-family: 'Maven Pro', sans-serif;
  font-size: 13px;
}

.bg-grey {
  background-color: #f2f3f5;
}

.grey {
  color: #8c8c8c;
}

.b--black-3 {
  border-color: rgba(0, 0, 0, 0.03);
}

.content-container {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.bg-green-status {
  background-color: #3bdea2;
}

.bg-red-status {
  background-color: #e25d55;
}

/* Drag and Drops */
.example-container {
  width: 400px;
  max-width: 100%;
  margin: 0 25px 25px 0;
  display: inline-block;
  vertical-align: top;
}

.example-list {
  //border: solid 1px $grey;
  min-height: 25px;
  //background: $grey;
  //border-radius: 4px;
  overflow: hidden;
  display: block;
}

.example-box {
  border: solid 0.5px #cacad3;
  margin-bottom: 1px;
  //border-bottom: solid 1px $grey;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: grab;
  background: white;
  font-size: 14px;
}

.list-item-no-border {
  // border: solid 0.5px #cacad3;
  // margin-bottom: 1px;
  //border-bottom: solid 1px $grey;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: grab;
  background: white;
  font-size: 14px;
  border-bottom: 0;
  &:last-of-type {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #aaa;
  }
}

.cdk-drop-list-dragging {
  background: lightgrey;
}
.cdk-drag-preview {
  background: $beboc-primary;
  color: #fff;
}
.simple-list {
  border-bottom: solid 1px $grey;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: white;
  font-size: 14px;
}

.disabled {
  cursor: not-allowed;
  color: #4e5b6b !important;
}

.bg-nexus-green {
  background-color: $nexus-green;
}

.bg-nexus-yellow {
  background-color: $nexus-yellow;
}

.bg-nexus-yellow-important {
  background-color: $nexus-yellow !important;
}

.bg-nexus-red {
  background-color: $nexus-red;
}

.bg-nexus-babyblue {
  background-color: $nexus-blue;
}

.bg-nexus-grey {
  background-color: $nexus-grey;
}

.container-bg {
  background: white;
  //box-shadow : 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3);
  border: 1px solid #ddd;
  border-radius: 7px;
}

h1,
h2,
h3,
h4 {
  color: $nexus-dark-blue;
}

.w-5 {
  width: 5%;
}

.mandatory:after {
  content: ' *';
}

/* Tables */
.td-height {
  height: 70px;
}

.legend-item {
  width: 10px;
  height: 10px;
  &.large {
    width: 15px;
    height: 15px;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-tooltip {
  white-space: pre-line;
}

.mat-form-field-infix {
  width: auto !important;
}

@media screen and (max-height: 600px) {
  .force-hide-in-small {
    display: none !important;
  }
}

.mh30 {
  max-height: 30rem;
}

.mw12 {
  max-width: 12rem;
}

.bg-supplier-percent-bar {
  background-color: #3ba5a0;
}

.nexus-primary-background {
  background-color: $beboc-primary;
}

.nexus-primary-color {
  color: $beboc-primary;
}
.nexus-light-gray-background {
  background: #f1f5f9;
}
.dashloading {
  width: 58px;
  height: 58px;
}
.nexus-slight-gray {
  color: #f1f5f9;
}
.nexus-light-gray {
  color: #94a3b8;
}
.greynumber {
  color: #64748b;
}
.flex-grow {
  flex-grow: 1;
}
.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs18 {
  font-size: 18px;
}
.fs28 {
  font-size: 28px;
}
.fs20 {
  font-size: 20px;
}
.nexus-neutral-gray {
  color: #1e293b;
}

.nexus-light-gray {
  color: #94a3b8;
}

.nexus-light-gray {
  color: #94a3b8;
}

.bg-sitewide {
  background-color: #f9f9f9;
}

.bg-job-completed {
  background-color: #aae58c;
}

.bg-job-started {
  background-color: #fbe171;
}

.bg-job-scheduled {
  background-color: #d8d8d8;
}

.bg-job-aborted {
  background-color: #f09898;
}

.bg-job-onroute {
  background-color: #fbe171;
}

.nexus-logo {
  height: 26px;
  margin-top: 4px;
}
.custom {
  height: 34px;
  &.flexheight {
    height: 100%;
    display: flex;
    &.ng-select-single {
      .ng-select-container {
        height: 100%;
      }
    }
  }
  &.ng-select .ng-select-container {
    border: 1px solid #0000001f;
  }
  &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: auto;
  }
}
// .bg-nexus-appointment-scheduled {
//   background-color: $nexus-appointment-scheduled;
// }

// .bg-nexus-appointment-scheduled-text {
//   color: $nexus-appointment-scheduled-text;
// }

.bg-nexus-appointment-booked {
  background-color: $nexus-appointment-scheduled;
}

.bg-nexus-appointment-booked-text {
  color: $nexus-appointment-scheduled-text;
}

.bg-nexus-appointment-not-started {
  background-color: $nexus-appointment-not-started;
}

.bg-nexus-appointment-not-started-text {
  color: $nexus-appointment-not-started-text;
}

.bg-nexus-appointment-on-route {
  background-color: $nexus-appointment-on-route;
}

.bg-nexus-appointment-on-route-text {
  color: $nexus-appointment-on-route-text;
}

.bg-nexus-appointment-on-site {
  background-color: $nexus-appointment-on-site;
}

.bg-nexus-appointment-on-site-text {
  color: $nexus-appointment-on-site-text;
}

.bg-nexus-appointment-started {
  background-color: $nexus-appointment-started;
}

.bg-nexus-appointment-started-text {
  color: $nexus-appointment-started-text;
}

.bg-nexus-appointment-aborted {
  background-color: $nexus-appointment-aborted;
}

.bg-nexus-appointment-aborted-text {
  color: $nexus-appointment-aborted-text;
}

.bg-nexus-appointment-paused {
  background-color: $nexus-appointment-paused;
}

.bg-nexus-appointment-paused-text {
  color: $nexus-appointment-paused-text;
}

.bg-nexus-appointment-completed {
  background-color: $nexus-appointment-completed;
}

.bg-nexus-appointment-completed-text {
  color: $nexus-appointment-completed-text;
}

.bg-nexus-appointment-bookedv2 {
  background-color: $nexus-appointment-bookedv2;
}

.bg-nexus-appointment-booked-textv2 {
  color: $nexus-appointment-scheduled-textv2;
}

.bg-nexus-appointment-not-startedv2 {
  background-color: $nexus-appointment-not-startedv2;
}

.bg-nexus-appointment-not-started-textv2 {
  color: $nexus-appointment-not-started-textv2;
}

.bg-nexus-appointment-on-routev2 {
  background-color: $nexus-appointment-on-routev2;
}

.bg-nexus-appointment-on-route-textv2 {
  color: $nexus-appointment-on-route-textv2;
}

.bg-nexus-appointment-on-sitev2 {
  background-color: $nexus-appointment-on-sitev2;
}

.bg-nexus-appointment-on-site-textv2 {
  color: $nexus-appointment-on-site-textv2;
}

.bg-nexus-appointment-startedv2 {
  background-color: $nexus-appointment-startedv2;
}

.bg-nexus-appointment-started-textv2 {
  color: $nexus-appointment-started-textv2;
}

.bg-nexus-appointment-abortedv2 {
  background-color: $nexus-appointment-abortedv2;
}

.bg-nexus-appointment-pausedv2 {
  background-color: $nexus-appointment-pausedv2;
}

.bg-nexus-appointment-aborted-textv2 {
  color: $nexus-appointment-aborted-textv2;
}

.bg-nexus-appointment-paused-textv2 {
  color: $nexus-appointment-aborted-textv2;
}

.bg-nexus-appointment-completedv2 {
  background-color: $nexus-appointment-completedv2;
}

.bg-nexus-appointment-completed-textv2 {
  color: $nexus-appointment-completed-textv2;
}

.bg-nexus-appointment-scheduledv2 {
  background-color: $nexus-appointment-scheduledv2;
}

.bg-nexus-appointment-scheduled-textv2 {
  color: $nexus-appointment-scheduled-textv2;
}

.beboc-field-description-size {
  font-size: 16px;
  padding-bottom: 1px;
}

.beboc-field-description-color {
  // nexus font size 13px
  color: #1e293b;
}

.beboc-field-value-size {
  font-size: 16px;
  font-weight: 400;
}
.fields-margin {
  padding-bottom: 15px;
}
.beboc-field-value-color {
  // nexus font size 18px
  color: #94a3b8;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.table-wrap {
  border-radius: 10px;
  overflow-y: auto;
}
.nexus-table {
  &.darkteal {
    thead {
      background-color: #009999;
    }
  }
  &.striped {
    tr:nth-child(even) {
      background: #f8fafc;
    }
  }
  caption {
    font-size: 15px;
    font-style: italic;
    padding: 10px;
    text-align: right;
  }

  background: white;
  border: 1px solid #e5e5e5;
  border-collapse: separate;
  border-radius: 7px;
  border-spacing: 0;
  caption-side: bottom;
  font-size: 12px;
  width: 100%;
  td,
  th {
    padding: 0.75rem;
  }
  thead {
    background-color: #86c2bc;
    color: white;
    font-size: 14px;
    text-align: left;

    td {
      border-top: none;
      border-left: solid 1px #49bab5;
    }
  }

  tbody {
    font-size: 13px;

    tr {
      transition: all 0.5s ease-out;

      //&:nth-of-type(even) {
      //	background: #fafafa;
      //}

      &.pointer:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      &.nexus-table-row-hover:hover {
        cursor: default;
        background-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  tfoot {
    font-size: 13px;
    font-weight: bold;
  }

  th {
    font-weight: normal;
    text-align: left;
  }

  td {
    border-left: solid 1px #e5e5e5;
    border-top: solid 1px #e5e5e5;

    &:first-child {
      border-left: none;
      text-align: left;
    }
  }
}

.nexus-table-centred {
  th {
    text-align: center !important;
  }

  text-align: center !important;
}

.small-toggle {
  .mat-icon-button {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
  .mat-button-wrapper {
    line-height: 37px;
  }
}
.nexus-button-free {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: background-color, width, left, padding;
  transition-timing-function: ease;
}
.nexus-button {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid black;
  // color: black;
  display: inline-block;
  font-size: 12px;
  // margin: 0;
  min-width: 90px;
  padding: 8px 20px 9px 20px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: background-color, width, left, padding;
  transition-timing-function: ease;
  line-height: 1.15;

  //&:hover:not(.disabled) {
  //  background-color: black;
  //  color: white;
  //}
  //
  //&.disabled {
  //  background-color: #ccc;
  //  border-color: #ccc;
  //  color: #646464 !important;
  //}
}

.nexus-button-primary {
  background-color: #f2f9f9;
  border: 1px solid $beboc-primary;
  color: $beboc-primary;

  &:hover:not(.disabled) {
    background-color: $beboc-primary;
    color: #fff;
    cursor: pointer;
  }
  &.disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #646464;
  }
}

.nexus-button-close {
  border: 1px solid #ef4444;
  color: #ef4444;
  background: transparent;
  &:hover:not(.disabled) {
    background: #ef4444;
    color: #fff;
    cursor: pointer;
  }
}

.nexus-button-red {
  background-color: transparent;
  border: 1px solid #dc2626;
  color: #dc2626;

  &:hover:not(.disabled) {
    background-color: #dc2626;
    color: #fff;
    cursor: pointer;
  }
  &.disabled {
    background-color: #ccc;
    border-color: #646464;
    color: #646464;
  }
}

.nexus-button-white {
  background-color: white;
  border: 1px solid black;
  color: black;

  &:hover:not(.disabled) {
    background-color: white;
    color: #fff;
  }
  &.disabled {
    background-color: #ccc;
    border-color: #646464;
    color: #646464;
  }
}

.nexus-button-logout {
  background-color: $beboc-primary;
  border: 1px solid white;
  color: white;

  &:hover {
    background-color: white;
    color: $beboc-primary;
  }
}

.nexus-button-bottom-align {
  align-self: flex-end;
}

.highlight-item {
  border-left: 3px solid $nexus-yellow;
}
.scroll {
  &::-webkit-scrollbar {
    width: 0.7em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
    outline: 0px solid slategrey;
    border-radius: 5px;
  }
}
.beboc-menu {
  height: calc(100vh - 50px);
  overflow: auto;
  .input-reset {
    background: #363636;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    color: #fff;
  }
}
input:focus::placeholder {
  color: transparent;
}
.list {
  li.menu-item {
    &.active {
      a {
        border-left: 3px solid #3ba5a0;
        background: #26a69a;
        color: #fff;
      }
      li a {
        background-color: #363636;
        border-left: 0px solid #3ba5a0;
      }
    }
    &:hover:not(.active) a {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      border-left: 3px solid #3ba5a0;
    }
    ul {
      li {
        &:hover:not(.active) a {
          background-color: rgba(255, 255, 255, 0.1);
          color: #fff;
          border-left: 3px solid #3ba5a0;
        }
        a {
          width: 100%;
        }
        &.active {
          a {
            border-left: 3px solid #3ba5a0;
            background: #26a69a;
            color: #fff;
          }
        }
      }
    }
  }
}
.hover-outline.outline-selected {
  background: #86c2bc;
  color: #fff;
}
.lh-title {
  color: teal;
}
.hover-outline {
  border: 0.5px solid grey;
  &:hover {
    background: #86c2bc;
    color: #fff;
  }
}
.appointments {
  .search {
    .input-reset {
      margin-top: 24px;
    }
  }
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    span {
      display: inline-block;
      position: relative;
      top: 2px;
    }
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 5px;
    border: 1px solid #dee2e6;
  }

  // Box hover
  &:hover + label:before {
    background: $main;
  }

  // Box focus
  //&:focus + label:before {
  //	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  //}

  // Box checked
  &:checked + label:before {
    background: $main;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.custom {
  &.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #cbd5e1;
  }
  &.placeholder-gray {
    input {
      color: #cbd5e1;
    }
  }
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.44rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.22rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #26a69a;
  background-color: #26a69a;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(38, 166, 154, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #6eded3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #97e7e0;
  border-color: #97e7e0;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-size: 0.875rem;
}

.custom-control-label::before {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 4.3px;
  left: -22.5px;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(38, 166, 154, 0.5);
}
.regioncode {
  color: teal;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.btn-delete {
  background: grey;
  color: #fff;
  opacity: 0.2;
  &:hover {
    background: $red;
    opacity: 1;
    color: #fff !important;
  }
  &.outlined {
    background: none;
    border: 1px solid $red;
    color: $red;
    &:hover {
      background: $red;
      color: #fff;
    }
  }
  &.text {
    background: none;
    border: 1px solid transparent;
    color: $red;
    &:hover {
      color: #000;
    }
  }
}
.teal {
  color: teal;
}

.bg-lightteal {
  background: #86c2bc;
  color: #fff;
}
.slottable {
  tbody {
    .lastslot {
      border-radius: 0px 0px 0px 15px;
    }
  }
}
.tealheader {
  th:first-of-type {
    border-radius: 15px 0px 0px 0px;
  }
  th:last-of-type {
    border-radius: 0px 15px 0px 0px;
  }
  tr {
    background: #86c2bc;
  }
  td,
  th {
    background: #86c2bc;
    color: #fff;
  }
}
.sidemenu {
  max-width: 250px;
}
.appointmentconfirmationtable {
  td {
    padding-bottom: 10px;
  }
  th {
    padding-bottom: 10px;
    color: #767676;
    font-size: 0.8rem;
  }
}
.teal {
  color: teal;
}
.w-45 {
  width: 45%;
}
.w-42 {
  width: 42%;
}
.w-16 {
  width: 16%;
}
.w-15 {
  width: 15%;

  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.btn-delete {
  background: grey;
  color: #fff;
  opacity: 0.2;
  &:hover {
    background: $red;
    opacity: 1;
    color: #fff !important;
  }
  &.outlined {
    background: none;
    border: 1px solid $red;
    color: $red;
    &:hover {
      background: $red;
      color: #fff;
    }
  }
  &.text {
    background: none;
    border: 1px solid transparent;
    color: $red;
    &:hover {
      color: #000;
    }
  }
}
.teal {
  color: teal;
}
.color-darkteal {
  color: #009999;
}
.bg-darkteal {
  background: #009999;
}
.slot-available {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: rgba(124, 118, 118, 0.25);
    border-radius: 25px;
    margin: auto auto;
  }
  &:hover:after {
    background: #86c2bc;
    transition: 1s;
  }
  &.teal:after {
    background: #86c2bc;
  }
}
.bg-lightteal {
  background: #86c2bc;
  color: #fff;
}
.slottable {
  tbody {
    .lastslot {
      border-radius: 0px 0px 0px 15px;
    }
  }
}
.tealheader {
  th:first-of-type {
    border-radius: 15px 0px 0px 0px;
  }
  th:last-of-type {
    border-radius: 0px 15px 0px 0px;
  }
  tr {
    background: #86c2bc;
  }
  td,
  th {
    background: #86c2bc;
    color: #fff;
  }
}
.sidemenu {
  max-width: 250px;
}
.appointmentconfirmationtable {
  td {
    padding-bottom: 10px;
  }
  th {
    padding-bottom: 10px;
    color: #767676;
    font-size: 0.8rem;
  }
}
.w-45 {
  width: 45%;
}
.w-15 {
  width: 15%;
}

/* CDK Overlay - Image */
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.75);
}

//Causes issue on suppliers
//.mat-dialog-container {
//  padding: 2px !important;
//  background: transparent;
//}
.posrightbtn {
  position: absolute;
  right: 0;
  top: 0;
}
.posleftbtn {
  position: absolute;
  left: 0;
  top: 0;
}
.nexus-button-two {
  &:hover {
    background: $beboc-primary;
    color: #fff;
  }
}

.not-allowed {
  cursor: not-allowed !important;
}

.f8 {
  font-size: 10px;
}
.today-gray {
  color: #555;
}

/* a11y */
input[type='checkbox']:focus + label {
  color: #45a59f;
  border-color: #45a59f;
}

select:focus {
  color: #45a59f;
  border-color: #45a59f;
}

input[type='radio']:focus + label {
  color: #45a59f;
  border-color: #45a59f;
}

.ng-select .ng-select-container {
  border-radius: 6px;
  border: solid 1px #3ba5a0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: rgb(0, 0, 0);
  font-size: 13px;
}
.ng-select.ng-select-single .ng-select-container {
  height: 31px;
}
.ng-select .ng-select-container {
  min-height: 31px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #333;
}

.popper {
  display: inline-block;
  background: #f8fafc;
  // padding: 10px;
  padding: 16px 24px;
  border-radius: 5px;
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.11));
  z-index: 99999;
  &[x-placement^='bottom'] {
    margin-top: 15px;
  }

  &[x-placement^='top'] {
    margin-bottom: 15px;
  }

  &[x-placement^='right'] {
    margin-left: 15px;
  }

  &[x-placement^='left'] {
    margin-right: 15px;
  }

  &__arrow {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    position: absolute;

    border: {
      style: solid;
      width: 10px;
      color: transparent;
    }
  }

  &[x-placement^='top'] &__arrow {
    border-bottom-width: 0;
    border-top-color: #f8fafc;
    bottom: -10px;
  }

  &[x-placement^='bottom'] &__arrow {
    border-top-width: 0;
    border-bottom-color: #f8fafc;
    top: -10px;
  }

  &[x-placement^='right'] &__arrow {
    border-left-width: 0;
    border-right-color: #f8fafc;
    left: -10px;
  }

  &[x-placement^='left'] &__arrow {
    border-right-width: 0;
    border-left-color: #f8fafc;
    right: -10px;
  }
}
.todayview {
  h3.dashboard-datepicker-time {
    font-weight: 400;
    font-size: 18px;
  }
}
.sixty-min-time {
  .timeline-unit {
    font-size: 12px;
  }
}
.timeline-unit {
  font-size: 14px;
  font-weight: 600;
  color: #1e293b;
}
.custom.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #009999;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  height: 0.75em;
  width: 0.75em;
  transform: rotate(135deg);
  top: -2px;
}
.boxs-white {
  box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.15);
}
.mdc-dialog__content,
.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: visible !important;
  &.overflow-scroll {
    overflow: scroll !important;
  }
}
.mat-mdc-icon-button.mat-mdc-button-base {
  width: 34px;
  height: 34px;
  padding: 4px;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
.mat-date-range-input-inner[ng-reflect-disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.mat-date-range-input input,
.mat-date-range-input span,
.mat-mdc-form-field.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label {
  font-size: 13px;
}
.mat-mdc-form-field-infix {
  min-height: 47px;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-font: 'Maven Pro', sans-serif;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
.mat-mdc-form-field .mdc-text-field {
  padding: 0;
}
.mat-date-range-input-container {
  padding-left: 15px;
}
html body .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
html body .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 14px;
  padding-bottom: 0px;
}
html body .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
html body .mat-mdc-form-field-infix {
  min-height: 38px;
}

/* Radio buttons form controls */
/* Custom radio button container */
.radio-container {
  display: flex; /* Align radio buttons horizontally */
  align-items: center; /* Center radio buttons vertically */
  margin-bottom: 0.5rem; /* Space below each radio button, adjust as needed */
}

/* Hide the default radio input visually but allow it to be focused */
.radio-custom input[type="radio"] {
  appearance: none; /* Remove platform-native styling */
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  margin: -1px; /* Keep it "accessible" for screen readers */
}

/* Custom radio button styling */
.radio-custom label {
  position: relative;
  cursor: pointer;
  padding-left: 30px; /* Space for the fake radio button */
  margin-right: 20px; /* Space between radio buttons, adjust as needed */
}

/* Outer circle */
.radio-custom label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px; /* Outer circle size */
  height: 20px; /* Outer circle size */
  border: 1px solid #000; /* Border color */
  background-color: #fff; /* Background color */
  border-radius: 50%;
  transform: translateY(-50%);
}

/* Inner circle */
.radio-custom label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 11px; /* Adjust this to center the inner circle */
  width: 12px; /* Inner circle size */
  height: 12px; /* Inner circle size */
  background-color: $nexus-green; /* Background color */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Hide initially */
}

/* Change styles when the radio button is checked */
.radio-custom input[type="radio"]:checked + label:before {
  border-color: #008000; /* Green border when selected */
}

.radio-custom input[type="radio"]:checked + label:after {
  opacity: 1; /* Show inner circle when selected */
}

.active-tab {
  border-radius: 0.5em 0.5em 0 0;
  background-color: $nexus-green;
  color: white;
}

.inactive-tab {
  border-radius: 0.5em 0.5em 0 0;
  background-color: $grey;
  border-style: solid;
  border-width: 1px;
  border-color: $nexus-green;
  border-bottom: none;
}
